import React from 'react';

const PageTitlePrivacyPolicy = () => {

    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h2 className="large">DATENSCHUTZERKLÄRUNG</h2>
            </div>
        </div>
    );
};

export default PageTitlePrivacyPolicy;
