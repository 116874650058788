import React, {Component} from 'react';
import Swiper from 'swiper';
import {withTranslation} from 'react-i18next';
import {HOST} from "../../settings";

class ServicesHome extends Component {
  componentDidMount() {
    new Swiper('.adv-slider-services .adv-swiper-container', {
      // ADV swiper
      noSwipingClass: 'adv-swiper-no-swiping',
      containerModifierClass: 'adv-swiper-container-',
      slideClass: 'adv-swiper-slide',
      slideBlankClass: 'adv-swiper-slide-invisible-blank',
      slideActiveClass: 'adv-swiper-slide-active',
      slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
      slideVisibleClass: 'adv-swiper-slide-visible',
      slideDuplicateClass: 'adv-swiper-slide-duplicate',
      slideNextClass: 'adv-swiper-slide-next',
      slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
      slidePrevClass: 'adv-swiper-slide-prev',
      slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
      wrapperClass: 'adv-swiper-wrapper',
      navigation: {
        disabledClass: 'adv-swiper-button-disabled',
        hiddenClass: 'adv-swiper-button-hidden',
        lockClass: 'adv-swiper-button-lock',
        nextEl: '.adv-2-swiper-button-next',
        prevEl: '.adv-2-swiper-button-prev',
      },
      spaceBetween: 0,
      slidesPerView: 'auto',
    });
  }

  render() {
    const {t} = this.props;
    return (
      <section id="services" className="block">
        <div className="adv-slider-services">
          <div className="adv-swiper-container">
            <div className="adv-swiper-wrapper services-items clearfix">
              <a title={t('services.service_1.title')}
                 className="adv-swiper-slide services-item"
                 href={HOST.PUBLIC_URL + '/service-dermatologie'}>
                <div className="services-item-content">
                  <h3 className="services-item-t-head">{t(
                    'services.service_1.title')}</h3>

                  <span
                    className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">{t(
                    'slider.read_more')}</span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img src="https://ucarecdn.com/5b2ca99c-855f-4bda-b20b-1ace4db66f1f/-/preview/-/quality/smart/-/format/auto/"
                         alt={t('services.service_1.title')}/>
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>

              <a title={t('services.service_2.title')}
                 className="adv-swiper-slide services-item"
                 href={HOST.PUBLIC_URL + '/service-operative-dermatologie'}>
                <div className="services-item-content">
                  <h3 className="services-item-t-head">{t(
                    'services.service_2.title')}</h3>

                  <span
                    className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">{t(
                    'slider.read_more')}</span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img src="https://ucarecdn.com/1c1ae218-8657-40dd-907b-0d776ef6652a/-/preview/-/quality/smart/-/format/auto/"
                         alt={t('services.service_2.title')}/>
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>

              <a title={t('services.service_3.title')}
                 className="adv-swiper-slide services-item"
                 href={HOST.PUBLIC_URL + '/service-asthetik'}>
                <div className="services-item-content">
                  <h3 className="services-item-t-head">{t(
                    'services.service_3.title')}</h3>

                  <span
                    className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">{t(
                    'slider.read_more')}</span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img src="https://ucarecdn.com/f56c3c15-d8f7-4fef-adc4-ca1e1c0fa70c/-/preview/-/quality/smart/-/format/auto/"
                         alt={t('services.service_3.title')}/>
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>

              <a title={t('services.service_4.title')}
                 className="adv-swiper-slide services-item"
                 href={HOST.PUBLIC_URL + '/service-removing'}>
                <div className="services-item-content">
                  <h3 className="services-item-t-head">{t(
                    'services.service_4.title')}</h3>

                  <span
                    className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">{t(
                    'slider.read_more')}</span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img src="https://ucarecdn.com/0afacc6b-1061-43f8-9587-7dabc1d0a340/-/preview/-/quality/smart/-/format/auto/"
                         alt={t('services.service_4.title')}/>
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>
            </div>

            <div className="adv-2-swiper-button-prev">
                            <span>
                                <span
                                  className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">{t(
                                  'slider.back')}</span>
                            </span>
            </div>

            <div className="adv-2-swiper-button-next">
                            <span>
                                <span
                                  className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">{t(
                                  'slider.forward')}</span>
                            </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ServicesHome);
