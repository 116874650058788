const getSettings = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    const isVercelApp = window.location.hostname.endsWith('vercel.app');

    let PUBLIC_URL;

    if (isProduction) {
        PUBLIC_URL = isVercelApp
            ? 'https://hautarzt-neuwulmstorf.vercel.app'
            : 'https://hautarzt-neuwulmstorf.de';
    } else {
        PUBLIC_URL = 'http://localhost:3000';
    }

    return { PUBLIC_URL };
};

export const HOST = {
    PUBLIC_URL: getSettings().PUBLIC_URL,
}