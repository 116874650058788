import React from 'react';
import {useTranslation} from 'react-i18next';
import {HOST} from "../../settings";

const Actions = () => {
  const {t} = useTranslation();
  return (
    <div className="header-content d-flex justify-content-between">
      <div className="header-left align-self-center">
        <div className="links">
          <div className="links-items">
            <div className="links-item">
              <a href={HOST.PUBLIC_URL + '/contacts'}
                 className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">{t(
                'main_page.menu_contacts')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
