export const supportedLanguages = [
  {
    id: 1,
    label: "de"
  },
  {
    id: 2,
    label: "en"
  },
  {
    id: 3,
    label: "ru"
  }
];

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);


