import React, {Fragment} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import {useTranslation} from "react-i18next";
import PageTitlePrivacyPolicy from "../blocks/page-title/PageTitlePrivacyPolicy";

const Datenschutz = () => {
    document.body.classList.add('page');
    const {t} = useTranslation();
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8"/>
                <title>{t('meta.title')}</title>
                <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="description" content={t('meta.description')}/>
                <meta name="keywords" content={t('meta.keywords')}/>
                <meta name="robots" content="index, follow, noodp"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="format-detection" content="telephone=no"/>
            </MetaTags>

            <Loading/>

            <Header logoColor="dark"/>

            <main id="main" className="site-main">
                <PageTitlePrivacyPolicy/>

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <p className="daten_text">
                                Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und halten uns strikt an die
                                Regeln der Datenschutzgesetze. Personenbezogene Daten werden auf unserer Website nur im
                                technisch notwendigen Umfang erhoben.
                            </p>
                            <p className="daten_text">
                                In keinem Fall werden die erhobenen Daten verkauft oder aus anderen Gründen an Dritte
                                weitergegeben.
                            </p>
                            <p className="daten_text">
                                Die nachfolgende Erklärung gibt Ihnen einen Überblick, wie wir diesen Schutz
                                gewährleisten und welche Art von Daten zu welchem Zweck erhoben werden:
                            </p>
                            <p className="daten_text">
                                Datenverarbeitung auf dieser Internetseite Wir bzw. unser Domainbetreiber erhebt und
                                speichert automatisch in den "Server Log Files" Informationen, die von Ihrem Browser an
                                uns übermittelt werden. Dies sind:
                            </p>
                            <ul className="daten_list">
                                <li>Browsertyp/-version</li>
                                <li>verwendetes Betriebssystem</li>
                                <li>Referrer URL (die zuvor besuchte Seite)</li>
                                <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
                                <li>Uhrzeit der Serveranfrage</li>
                            </ul>
                            <p className="daten_text">
                                Diese Daten sind für uns nicht bestimmten Personen zuordenbar. Eine Zusammenführung
                                dieser Daten mit anderen Datenquellen wird nicht vorgenommen, die Daten werden zudem
                                nach einer statistischen Auswertung gelöscht. Darüber hinausgehende personenbezogene
                                Angaben, wie Ihr Name, Ihre Anschrift, Ihre Telefonnummer oder E-Mail-Adresse werden bei
                                uns nicht erfasst.
                            </p>
                            <p className="daten_text">
                                Nutzung personenbezogener Daten Wenn Sie uns persönliche Daten zur Verfügung stellen,
                                werden diese Daten nur für den angegebenen Zweck genutzt, dem Sie vor der Dateneingabe
                                zugestimmt haben. Wir werden Ihre persönlichen Daten nur solange behalten, wie dies für
                                den vorgesehenen Zweck erforderlich oder gesetzlich vorgeschrieben ist.
                            </p>
                            <p className="daten_text">
                                Datensicherheit und Datenschutz Ihre personenbezogenen Daten werden durch Ergreifung
                                aller technischen und administrativen Möglichkeiten so gespeichert, dass sie für Dritte
                                nicht zugänglich sind. Bei der Kommunikation per E-Mail kann technisch eine vollständige
                                Datensicherheit nicht gewährleistet werden, so dass wir Ihnen bei vertraulichen
                                Informationen den Postweg empfehlen.
                            </p>
                            <p className="daten_text">
                                Cookies Auf unseren Internetseiten verwenden wir in der Regel keine Cookies. Nur in
                                Ausnahmefällen werden sog. "Session-Cookies" eingesetzt, die nach dem Ende Ihres
                                Besuches automatisch gelöscht werden.
                            </p>
                            <p className="daten_text">
                                Abrufprotokollierung Diese Website benutzt Google Analytics, einen Webanalysedienst der
                                Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf
                                Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch
                                Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
                                Website (einschließlich Ihrer IP-Adresse, die jedoch vor dem Speichern mit der Methode
                                _anonymizeIp anonymisiert wird, so dass sie nicht mehr einem Anschluss zugeordnet werden
                                kann) werden an einen Server von Google in den USA übertragen und dort gespeichert.
                                Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
                                Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
                                weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu
                                erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen,
                                sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von
                                Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von
                                Google in Verbindung bringen. Sie können die Installation der Cookies durch eine
                                entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
                                darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
                                Website vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich
                                mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen
                                Art und Weise und zu dem zuvor benannten Zweck einverstanden. Sie können der Erhebung
                                der Daten durch Google-Analytics mit Wirkung für die Zukunft widersprechen, indem sie
                                z.B. ein spezielles Add-on für Ihren Browser installieren.
                            </p>
                            <p className="daten_text" style={{margin: '0px 0% 2%'}}>
                                Unsere Datenschutzbeauftragte erreichen Sie unter der Praxisanschrift:
                            </p>
                            <p className="daten_text" style={{margin: '0px 0% 0%'}}>
                                Hautarzt Sumenko
                            </p>
                            <p className="daten_text" style={{margin: '0px 0% 0%'}}>
                                Privatpraxis
                            </p>
                            <p className="daten_text" style={{margin: '0px 0%'}}>
                                Fritz-Reuter-Str. 16, 21629 Neu Wulmstorf
                            </p>
                            <p className="daten_text" style={{margin: '0px 0% 3%'}}>
                                E-Mail: <a href="mailto:info@hautarzt-neuwulmstorf.de">info@hautarzt-neuwulmstorf.de</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>

            <Footer/>
        </Fragment>
    );
};

export default Datenschutz;
