import React from 'react';

const Copyright = () => {
  return (
    <div className="copyright">
      <p>© {new Date().getFullYear()} Design <a href="https://www.adveits.com">Adveits</a> | Web development <a href="https://www.linkedin.com/in/romanlebedenko/">Roman Lebedenko</a></p>
    </div>
  );
};

export default Copyright;
