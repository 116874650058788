import React from 'react';
import {useTranslation} from 'react-i18next';
import {HOST} from "../../settings";

const ContactsUs = () => {
  const {t} = useTranslation();
  return (
    <section id="contact-us" className="block">
      <div className="bg-pink-light spacer p-top-xl p-bottom-xl">
        <div className="wrapper text-center">
          <div className="title">
            <h2>{t('contacts_page.title')}</h2>
          </div>

          <a href={HOST.PUBLIC_URL + '/contacts'}
             className="btn btn-outline-secondary">{t('contacts_page.button')}</a>
        </div>
      </div>
    </section>
  );
};

export default ContactsUs;
