import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import {useTranslation} from 'react-i18next';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from '../blocks/services/ServicesHome';
import AboutsUs from '../blocks/about/AboutUs';


const Home = () => {
    document.body.classList.add( 'home' );
    document.body.classList.add( 'header-absolute-true' );
    const {t} = useTranslation();

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>{t('meta.title')}</title>
                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content={t('meta.keywords')} />
                <meta name="description" content={t('meta.description')}/>
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />

                        <ContactsUs />

                        <ServicesHome />

                        <AboutsUs />

                        <div id="img" className="block spacer p-top-xl">
                            <div className="img-no-wrap-1 img-gray">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="https://ucarecdn.com/038abf02-dd0a-49e6-83ad-b00a080e10a1/-/preview/-/quality/smart/-/format/auto/" alt="Beauty" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
