import React from 'react';
import Dropdown from 'react-dropdown';
import {supportedLanguages} from '../../serviceFunctions';
import {useTranslation} from 'react-i18next';

const Languages = ({color}) => {

  const {i18n} = useTranslation();

  const defaultLanguage = i18n.resolvedLanguage;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <ul className="nav">
      <li className="lang-item current-lang">
        <Dropdown className={color === 'dark' ?
          'page-title-dropdown-black' :
          'page-title-dropdown'}
                  placeholderClassName="page-title-dropdown-placeholder"
                  controlClassName="page-title-dropdown-control"
                  menuClassName={color === 'dark' ?
                    'page-title-dropdown-menu-black' :
                    'page-title-dropdown-menu'}
                  options={supportedLanguages.filter(
                    language => language.label !== i18n.resolvedLanguage)}
                  onChange={(lang) => changeLanguage(lang.value)}
                  value={defaultLanguage}/>
      </li>
    </ul>
  );
};

export default Languages;