import React, { Component, Fragment } from 'react';
import SwipeBox from 'fslightbox-react';

const image_1 = 'https://ucarecdn.com/854dad35-3d32-4869-bac4-9eee646f8c34/-/preview/-/quality/smart/-/format/auto/';
const image_2 = 'https://ucarecdn.com/cb1a556f-aff5-4b0a-bd01-d5bf220b5cbf/-/preview/-/quality/smart/-/format/auto/';
const image_3 = 'https://ucarecdn.com/e4dfa1e2-f530-48d3-8401-3484a86bb962/-/preview/-/quality/smart/-/format/auto/';
const image_4 = 'https://ucarecdn.com/73e5b6de-ccac-439c-8763-763c57faa676/-/preview/-/quality/smart/-/format/auto/';
const image_5 = 'https://ucarecdn.com/cf79d564-fb9f-47d7-bc3d-4eb97591da01/-/preview/-/quality/smart/-/format/auto/';
const image_6 = 'https://ucarecdn.com/a7d82574-4c3c-4eaa-bfee-1da3baf9e6e0/-/preview/-/quality/smart/-/format/auto/';
const image_7 = 'https://ucarecdn.com/129170c8-50aa-4b59-b06b-8b10e66f00e4/-/preview/-/quality/smart/-/format/auto/';
const image_8 = 'https://ucarecdn.com/5b2ca99c-855f-4bda-b20b-1ace4db66f1f/-/preview/-/quality/smart/-/format/auto/';

class SwipeBoxComponent extends Component {
    state = {
        isVisible: false,
        slide: 0,
    };
    showSlide = ( slide, event ) => {
        event.preventDefault();
        this.setState( {
            isVisible: !this.state.isVisible,
            slide: slide
        } );
    };


    render() {
        return (
            <Fragment>
                <SwipeBox
                    toggler={this.state.isVisible}
                    slide={this.state.slide}
                    urls={[
                        image_1,
                        image_2,
                        image_3,
                        image_4,
                        image_5,
                        image_6,
                        image_7,
                        image_8
                    ]}
                />

                {/*1*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(1, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_1}
                                    alt="praxis 1"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*2*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(2, event)} title="" className="gallery-item swipebox"
                       href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_2}
                                    alt="praxis 2"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*3*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(3, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_3}
                                    alt="praxis 3"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*4*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(4, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_4}
                                    alt="praxis 4"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*5*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(5, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_5}
                                    alt="praxis 5"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*6*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(6, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_6}
                                    alt="praxis 6"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*7*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(7, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_7}
                                    alt="praxis 7"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/*8*/}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(8, event)} title="" ref={this.swipeImg}
                       className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img
                                    src={image_8}
                                    alt="praxis 8"/>
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>


            </Fragment>
        );
    }
}

export default SwipeBoxComponent;
