import React, {Component, Fragment} from 'react';
import {Modal} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import ModalMenuPrimary from '../../blocks/menu/ModalMenuPrimary';
import ModalMenuSecondary from '../../blocks/menu/ModalMenuSecondary';

class Menu extends Component {

  constructor(context) {
    super(context);

    this.state = {
      showModal: false,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({showModal: true});
  }

  close() {
    this.setState({showModal: false});
  }

  render() {
    const {t} = this.props;
    return (
      <Fragment>
        <div className="menu-toggle">
          <button onClick={this.open.bind(this)} type="button"
                  className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto"
                  data-toggle="modal" data-target="#menu-modal">{t(
            'main_page.menu_title')}
          </button>
        </div>

        <Modal
          className="menu-modal"
          show={this.state.showModal}
          onHide={this.close}
          backdrop={false}
          aria-labelledby="menu-modal"
          aria-hidden="true"
          dialogClassName="modal-full"
          ref={node => this.chart = node}>
          <div className="wrapper">
            <div className="modal-content">
              <div className="modal-header modal-header-top">
                <div
                  className="header-content d-flex justify-content-center w-100">

                  <div className="header-center align-self-center">
                    <HeaderLogo logoColor="light"/>
                  </div>
                </div>
              </div>

              <div className="modal-body modal-body-centered">
                <div className="animated fadeinright w-100">
                  <ModalMenuPrimary/>

                  <ModalMenuSecondary/>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const MenuModal = withTranslation()(Menu);

export default MenuModal;
