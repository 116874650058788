
import React from 'react';
import {useTranslation} from 'react-i18next';
import {HOST} from "../../settings";

const BackToServices = () => {
  const {t} = useTranslation();
    return (
        <div id="back-to-services" className="block spacer p-top-xl">
            <div className="text-center">
                <a href={ HOST.PUBLIC_URL + "/services" } className="btn btn-outline-secondary">{t('services.button_back')}</a>
            </div>
        </div>
    );
};

export default BackToServices;
