import React from 'react';
import useMobileDetect from 'use-mobile-detect-hook';

const AboutImg = () => {
  const detectMobile = useMobileDetect();
  return (
    <div id="about-us-img" className="block">
      <div className="about-img">
        <div className="img object-fit">
          <div className="object-fit-cover">
            {detectMobile.isMobile() ?
              <img src="https://ucarecdn.com/02c71ddf-d6b0-4b2c-a393-f4c5418855e8/-/preview/-/quality/smart/-/format/auto/" alt="About us"/> :
              <img src="https://ucarecdn.com/70f42e92-b55c-4d63-8300-920608081390/-/preview/-/quality/smart/-/format/auto/" alt="About us"/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutImg;
