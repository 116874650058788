import React from 'react';
import {useTranslation} from 'react-i18next';
import ServicesContentItemsData from '../../data/services/servicesContentItems';
import {HOST} from "../../settings";

const ServicesContent = () => {
  const {t} = useTranslation();
  return (
    <div className="wrapper">
      <div className="content">
        <div className="row gutter-width-sm with-pb-sm services-items">
          {ServicesContentItemsData &&
          ServicesContentItemsData.map((item, key) => {
            return (
              <div key={key} className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <a title={t(item.title)} className="services-item"
                   href={HOST.PUBLIC_URL + item.link}>
                  <div className="services-item-content">
                    <h3 className="services-item-t-head">{t(item.title)}</h3>

                    <span
                      className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">{t('slider.read_more')}</span>
                  </div>

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img src={item.imgSrc} alt={t(item.title)}/>
                    </div>
                  </div>

                  <div className="img-bg-color"></div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServicesContent;
