import React from 'react';
import scriptLoader from 'react-async-script-loader';
import GoogleMapsStyle from '../../data/google-maps/googleMapsStyle';

class Maps extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.map = null;
  }

  componentWillReceiveProps({isScriptLoaded, isScriptLoadSucceed}) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      const address_latlng = new window.google.maps.LatLng(53.46208, 9.78237);
      // load finished
      if (isScriptLoadSucceed) {
        this.map = new window.google.maps.Map(this.mapRef.current, {
          center: address_latlng,
          zoom: 18,
          styles: GoogleMapsStyle,
        });

        this.map.setCenter(address_latlng);

        // Standard marker
        const marker = new window.google.maps.Marker({
          position: address_latlng,
          map: this.map,
          title: 'Hautarzt Sumenko',
        });

        // Adding event listener to open the location directly in Google Maps
        marker.addListener('click', () => {
          window.open(`https://www.google.com/maps?q=${address_latlng.lat()},${address_latlng.lng()}`, '_blank');
        });

      } else this.props.onError();
    }
  }

  render() {
    return (
        <div id="google-maps" ref={this.mapRef}></div>
    );
  }
}

export default scriptLoader(
    ['https://maps.googleapis.com/maps/api/js?key=AIzaSyCBlr2s-JA3hMtFGtxhTtDGQvj6TwNq4wI'])(
    Maps);
