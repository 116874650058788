import React from 'react';
import {Link} from 'react-scroll';
import {useTranslation} from 'react-i18next';

const PageTitleHome = () => {
  const {t} = useTranslation();

  return (
    <section id="page-title" className="block with-img"
             style={{backgroundImage: 'url(assets/img/images/background-main_page.jpeg)'}}>
      <div className="wrapper text-center d-flex">
        <div className="align-self-center w-100">
          <div className="title">
            <h2 className="large">{t('main_page.headline')}</h2>
            <p>{t('main_page.subheadline')}</p>
            <p style={{margin: 0}}>{t('main_page.name')}</p>
            <p>{t('main_page.specialization')}</p>
          </div>

          <div className="spacer p-top-lg no-space">
            <Link href="#contact-us"
                  to="contact-us"
                  spy={true}
                  smooth={true}
                  duration={0}
                  className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id">
              {t('main_page.scroll')}
            </Link>
          </div>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
};

export default PageTitleHome;
