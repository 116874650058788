import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="mailto:info@hautarzt-neuwulmstorf.de">info@hautarzt-neuwulmstorf.de</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Email</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>Fritz-Reuter-Str. 16 <br/>21629 Neu Wulmstorf</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Address</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>(040) 370 888 07</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Phone</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>(040) 303 301 28</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Fax</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="https://www.instagram.com/hautarzt_sumenko" target="_blank"
                               rel="noopener noreferrer">https://www.instagram.com/hautarzt_sumenko</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Instagram</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
