import React, {Fragment} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleAbout from '../blocks/page-title/PageTitleAbout';
import AboutImg from '../blocks/about/AboutImg';
import {useTranslation} from "react-i18next";

const About = () => {
  document.body.classList.add('page');
  const {t} = useTranslation();
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8"/>
          <title>{t('meta.title')}</title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="description" content={t('meta.description')}/>
        <meta name="keywords" content={t('meta.keywords')}/>
        <meta name="robots" content="index, follow, noodp"/>
        <meta name="googlebot" content="index, follow"/>
        <meta name="format-detection" content="telephone=no"/>
      </MetaTags>

      <Loading/>

      <Header logoColor="dark"/>

      <main id="main" className="site-main">
        <PageTitleAbout/>

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
                <div className="clearfix">
                    <AboutImg/>

                    <section id="about-us" className="block spacer p-top-xl">
                        <div className="wrapper">
                            <div className="row gutter-width-sm justify-content-end">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="description">
                                        <p style={{
                                            whiteSpace: 'pre-line',
                                        }}>{t('about_me_page.about_me_1')}</p>
                                        <p style={{
                                            whiteSpace: 'pre-line',
                                        }}>{t('about_me_page.about_me_2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*<AboutCertificate/>*/}

                </div>
            </div>
          </div>
        </div>
      </main>

        <Footer/>
    </Fragment>
  );
};

export default About;
