import React, {Component} from 'react';
import emailjs from 'emailjs-com';
import {withTranslation} from 'react-i18next';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                vorname: '',
                nachname: '',
                telefonnummer: '',
                email: '',
                message: '',
            },
            serviceId: 'service_8b6cst9',
            templateId: 'template_1n73sef',
            userId: 'user_DbJyTNfIO2GwfcEdOYm4m',
            successMessage: 'Sender\'s message was sent successfully',
            warningMessage: 'Fill up the form, please!',
            errorMessage: 'Something go wrong. Try again later!',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000,
        };
    }

    submitForm = async e => {
        e.preventDefault();

        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }

        this.setState({isSubmitting: true});

        emailjs.send(this.state.serviceId, this.state.templateId,
            this.state.values, this.state.userId).then(response => {
            if (response.status === 200) {
                this.setState({
                    responseMessage: this.state.successMessage,
                    values: { // Reset form values after success
                        vorname: '',
                        nachname: '',
                        telefonnummer: '',
                        email: '',
                        message: '',
                    },
                });
            } else {
                this.setState({responseMessage: this.state.errorMessage});
            }

            this.callAlert(this.state.responseMessage, response.status);
        }).catch(error => {
            this.callAlert(this.state.errorMessage, 'error');
        });
    };

    removeAlert = () => {
        clearTimeout(this.state.alertTimeout);
        this.setState({
            alertTimeout: setTimeout(function() {
                    var element = document.querySelector('#alert');
                    element.classList.remove('fadeIn');
                    element.classList.add('fadeOut');
                    setTimeout(function() {
                        element.remove();
                    }, 900);
                }, this.state.delay,
            ),
        });
    };

    callAlert = (message, type) => {
        if (!document.querySelector('#alert')) {
            if (type === 200) {
                this.setState({alertClass: 'success'});
            }

            if (type !== 200) {
                this.setState({alertClass: 'danger'});
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
                this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector('#cf-1');

            element.insertAdjacentHTML('beforeend', alert);

            this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value,
            },
        });

    render() {
        const {t} = this.props;
        return <>
            <form method="post" onSubmit={this.submitForm} id="cf-1"
                  className="contact-form">
                <div className="form-group form-group-lg">
                    <p className="input-group gutter-width-sm no-space">
                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0"
                                   htmlFor="cf-1-name-surename">{t(
                                'contacts_page.form.name')}*</label>

                            <input name="vorname"
                                   value={this.state.values.vorname}
                                   onChange={this.handleInputChange}
                                   type="text"
                                   id="cf-1-name-surename"
                                   placeholder={t(
                                       'contacts_page.form.placeholder_name')}
                                   required="required"/>
                        </span>

                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0"
                                   htmlFor="cf-1-services">{t(
                                'contacts_page.form.surname')}</label>

              <input name="nachname"
                     value={this.state.values.nachname}
                     onChange={this.handleInputChange}
                     type="text"
                     id="cf-1-name-surename"
                     placeholder={t(
                         'contacts_page.form.placeholder_surname')}
              />
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-lg">
                    <p className="input-group gutter-width-sm no-space">
                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0"
                                   htmlFor="cf-1-phone">{t(
                                'contacts_page.form.phone')}</label>

                            <input name="telefonnummer"
                                   value={this.state.values.telefonnummer}
                                   onChange={this.handleInputChange}
                                   type="text"
                                   id="cf-1-phone"
                                   placeholder={t(
                                       'contacts_page.form.placeholder_phone')}
                            />
                        </span>

                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0"
                                   htmlFor="cf-1-date">{t(
                                'contacts_page.form.email')}*</label>
                            <input name="email"
                                   value={this.state.values.email}
                                   onChange={this.handleInputChange}
                                   type="email"
                                   id="cf-1-date"
                                   placeholder={t(
                                       'contacts_page.form.placeholder_email')}
                                   required="required"/>
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-lg">
                    <label className="form-label-lg before after mb-0"
                           htmlFor="cf-1-message">{t(
                        'contacts_page.form.message')}*</label>

                    <textarea name="message"
                              value={this.state.values.message}
                              onChange={this.handleInputChange}
                              id="cf-1-message"
                              placeholder={t(
                                  'contacts_page.form.placeholder_message')}
                              required="required"/>
                </div>

                <div className="form-group form-group-lg mb-0">
                    <button type="submit" className="btn btn-outline-secondary">{t(
                        'contacts_page.form_button')}</button>
                </div>
            </form>
        </>
    }
}

export default withTranslation()(ContactForm);
