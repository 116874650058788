import React from 'react';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from '../../serviceFunctions';
import {HOST} from "../../settings";

const ModalMenuPrimary = () => {
  const {t} = useTranslation();
  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li className={'nav-item' +
        (window.location.pathname === '/' ? ' current-nav-item' : '')}>
          <a title="Home" href={HOST.PUBLIC_URL + '/'}>{capitalizeFirstLetter(t('main_page.menu_home'))}</a>
        </li>

        <li className={'nav-item' +
        (window.location.pathname === '/services' ? ' current-nav-item' : '')}>
          <a title="Services"
             href={HOST.PUBLIC_URL + '/services'}>{capitalizeFirstLetter(t('main_page.menu_services'))}</a>
        </li>

        <li className={'nav-item' +
        (window.location.pathname === '/gallery' ? ' current-nav-item' : '')}>
          <a title="Gallery"
             href={HOST.PUBLIC_URL + '/clinic'}>{capitalizeFirstLetter(t('main_page.menu_gallery'))}</a>
        </li>

        <li className={'nav-item' +
        (window.location.pathname === '/about' ? ' current-nav-item' : '')}>
          <a title="About us" href={HOST.PUBLIC_URL + '/about-us'}>{capitalizeFirstLetter(t('main_page.menu_about_us'))}</a>
        </li>

        <li className={'nav-item' +
        (window.location.pathname === '/contacts' ? ' current-nav-item' : '')}>
          <a title="Contacts"
             href={HOST.PUBLIC_URL + '/contacts'}>{capitalizeFirstLetter(t('main_page.menu_contacts'))}</a>
        </li>
      </ul>
    </nav>
  );
};

export default ModalMenuPrimary;
