import React from 'react';
import {useTranslation} from 'react-i18next';

const PageTitleContacts = () => {
  const {t} = useTranslation();
  return (
    <div id="page-title">
      <div className="wrapper text-center">
        <h1 className="large">{t('contacts_page.title')}</h1>
      </div>
    </div>
  );
};

export default PageTitleContacts;
