import React, {Fragment} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import {useTranslation} from "react-i18next";
import PageTitleImprint from "../blocks/page-title/PageTitleImprint";

const Impressum = () => {
    document.body.classList.add('page');
    const {t} = useTranslation();
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8"/>
                <title>{t('meta.title')}</title>
                <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="description" content={t('meta.description')}/>
                <meta name="keywords" content={t('meta.keywords')}/>
                <meta name="robots" content="index, follow, noodp"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="format-detection" content="telephone=no"/>
            </MetaTags>

            <Loading/>

            <Header logoColor="dark"/>

            <main id="main" className="site-main">
                <PageTitleImprint/>

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                                <p style={{marginBottom: '8px'}}>Hautarzt Sumenko</p>
                                <p style={{marginBottom: '8px'}}>Privatpraxis</p>
                                <p style={{marginBottom: '8px'}}>Fritz-Reuter-Str. 16, 21629 Neu Wulmstorf</p>
                                <p style={{marginBottom: '60px'}}>
                                    E-Mail: <a
                                    href="mailto:info@hautarzt-neuwulmstorf.de">info@hautarzt-neuwulmstorf.de</a>
                                </p>
                            </div>

                            <h2 className="imp_title">Berufsbezeichnung und berufsrechtliche Regelungen</h2>
                            <p className="imp_text">
                                Berufsbezeichnung: Arzt, Facharztbezeichnung Hautarzt, ZW Notfallmedizin
                                Verliehen durch: Bundesrepublik Deutschland
                            </p>
                            <p className="imp_text">
                                Zuständige Kammer: Landesärztekammer Niedersachsen, Berliner Allee 20; 30175 Hannover
                                Es gelten folgende berufsrechtliche Regelungen: Berufsordnung der Landesärztekammer von Niedersachsen
                                Regelungen einsehbar unter: Gesetzblatt des Landes Niedersachsen 2001 Teil1. Seite 3721 – 3727 (BGBL 2001 Teil I s.3721-3727)
                            </p>

                            <h2 className="imp_title">Verbraucherstreitbeilegung/ Universalschlichtungsstelle</h2>
                            <p className="imp_text">
                                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                            </p>

                            <h2 className="imp_title">Haftung für Inhalte</h2>
                            <p className="imp_text">
                                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
                                zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                            </p>
                            <p className="imp_text">
                                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                            </p>

                            <h2 className="imp_title">Haftung für Links</h2>
                            <p className="imp_text">
                                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                                Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                                Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                            </p>
                            <p className="imp_text">
                                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                            </p>

                            <h2 className="imp_title">Urheberrecht</h2>
                            <p className="imp_text">
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                                Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                                bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                                nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                            </p>
                            <p className="imp_text">
                                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                                Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                                aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
                                derartige Inhalte umgehend entfernen.
                            </p>

                            <h2 className="imp_title">Bildrechte</h2>
                            <p className="imp_text">
                                Die HAUTARZTPRAXIS ist Urheber der Fotos und Abbildungen („Werke“), die auf dieser Webseite dargestellt sind; respektive ist
                                die Privatpraxis Hautarzt Sumenko zur Nutzung der dargestellten urheberrechtlich geschützten Werke berechtigt.
                                Dazu bestehen entsprechende Lizenzvereinbarungen für die betreffenden Werke.
                            </p>
                        </div>
                    </div>
                </div>
            </main>

            <Footer/>
        </Fragment>
    );
};

export default Impressum;
