import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Services from './pages/Services';
import RemovingTattoo from './pages/RemovingTattoo';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import YButton from "./components/YButton/YButton";
import ServiceOperativeDermatologie from "./pages/Operative-Dermatologie";
import ServiceKlassischeDermatologie from "./pages/Klassische-Dermatologie";
import ServiceAsthetik from "./pages/Asthetik";
import Clinic from "./pages/Clinic";
import { CookieConsent, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import useMobileDetect from "use-mobile-detect-hook";

function App() {
  const {t} = useTranslation();
  const detectMobile = useMobileDetect();

  useEffect(() => {
    const consent = getCookieConsentValue("myAppCookieConsent");

    if (consent === "true") {
      // Load gtag.js script
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10788312548';
      script.async = true;
      document.head.appendChild(script);

      // Initialize gtag
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-10788312548');
      };
    }
  }, []);

  const handleAcceptCookie = () => {
    // Reload the page or load the script after user accepts
    window.location.reload();
  };

  return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/contacts" component={Contacts} />
            <Route exact path="/clinic" component={Clinic} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/service-dermatologie" component={ServiceKlassischeDermatologie} />
            <Route exact path="/service-operative-dermatologie" component={ServiceOperativeDermatologie} />
            <Route exact path="/service-asthetik" component={ServiceAsthetik} />
            <Route exact path="/service-removing" component={RemovingTattoo} />
            <Route exact path="/imprint" component={Impressum} />
            <Route exact path="/privacy-policy" component={Datenschutz} />
            {/*<Route exact component={ page404 } />*/}
          </Switch>
        </Router>
        <YButton />
        <CookieConsent
            location="bottom"
            enableDeclineButton
            buttonText={t('cookies.accept')}
            declineButtonText={t('cookies.decline')}
            cookieName="myAppCookieConsent"
            style={{ background: "#242424", color: "#FFF", lineHeight: "1.5", fontSize: "14px" }}
            buttonStyle={{ color: "white", fontSize: "14px", background: "#6D7978", borderRadius: "5px", border: "1px solid #FFF", marginRight: detectMobile.isMobile() ? "0" : "300px" }}
            declineButtonStyle={{ background: "rgba(138,138,138,0)", color: "#FFF", fontSize: "14px", borderRadius: "5px", border: "1px solid #FFF" }}
            expires={150}
            onAccept={handleAcceptCookie}
            onDecline={() => resetCookieConsentValue("myAppCookieConsent")}
        >
          {t('cookies.text')}
        </CookieConsent>
      </>
  );
}

export default App;
