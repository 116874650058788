import React from 'react';
import 'react-dropdown/style.css';
import {useTranslation} from 'react-i18next';

import HeaderLogo from '../../blocks/logo/HeaderLogo';
import MenuModal from '../../components/modal/MenuModal';
import Actions from '../actions/Actions';

import Languages from '../language/languages';
import {HOST} from "../../settings";

const Header = ({logoColor}) => {
  const {t} = useTranslation();

  return (
    <header id="header" className="site-header">
      <div className="wrapper">
        <div className="header-content d-flex justify-content-between">
          <div className="header-left align-self-center">
            <div className="links">
              <div className="links-items">
                <div className="links-item">
                  <a href={HOST.PUBLIC_URL + '/'}
                     className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">{t(
                    'main_page.menu_home')}</a>
                </div>
              </div>
            </div>
            <div className='languages-left'>
            <Languages color={logoColor}/>
            </div>
          </div>

          <div className="header-center align-self-center">
            <HeaderLogo logoColor={logoColor}/>
          </div>

          <div className="header-right d-flex justify-content-end">
            <div className="d-flex align-items-center">

              <MenuModal/>
              <Actions/>
              <div className='languages-right'>
                <Languages color={logoColor}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
