import React from 'react';

const PageTitleImprint = () => {

  return (
      <div id="page-title">
        <div className="wrapper text-center">
          <h2 className="large">Impressum</h2>
        </div>
      </div>
  );
};

export default PageTitleImprint;
