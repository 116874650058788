import React, { useState } from 'react';
import './styles.css';
import {useTranslation} from "react-i18next";

const YButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {t} = useTranslation();

    const toggleModal = (e) => {
        e.preventDefault();
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div>
            <a className="yButton" href="#" onClick={toggleModal}>
                <div className="yButtonBackground"></div>
                <div className="yButtonWave"></div>
                <div className="yButtonText">{t('termin_widget.button')}</div>
                <div className="yButtonIcon"></div>
            </a>
            {isModalOpen && (
                <div className="modalOverlay" onClick={toggleModal}>
                    <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                        <span className="modalClose" onClick={toggleModal}>&times;</span>
                        <iframe
                            style={{ width: '100%', height: '700px', maxHeight: '100vh' }}
                            src='https://app.arzt-direkt.de/hautarzt-neuwulmstorf/booking'
                            title="Booking Iframe"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default YButton;
