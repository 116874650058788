import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

const AboutsUs = () => {
  const {t} = useTranslation();
  return (
    <section id="about-us" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm justify-content-end">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="description">
              <p style={{
                whiteSpace: 'pre-line',
              }}>
                <Trans i18nKey="about_me_page.text" components={{strong: <strong/>, pre: <pre/>}}/>
              </p>
              <p style={{fontWeight: 'bold'}}>{t('about_me_page.text_2')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutsUs;
