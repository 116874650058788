import React from 'react';
import {useTranslation} from 'react-i18next';
import {HOST} from "../../settings";

const ModalMenuSecondary = () => {
    const {t} = useTranslation();
    return (
        <nav className="menu-secondary">
            <ul className="nav">
                <li className="nav-item">
                    <a title="Terms & Conditions" href={ HOST.PUBLIC_URL + "/imprint" }>{t('main_page.menu_terms')}</a>
                </li>

                <li className="nav-item">
                    <a title="Privacy Policy" href={ HOST.PUBLIC_URL + "/privacy-policy" }>{t('main_page.menu_privacy')}</a>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuSecondary;
