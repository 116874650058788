import React, {Fragment} from 'react';
import MetaTags from 'react-meta-tags';
import {useTranslation} from 'react-i18next';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside
  from '../blocks/page-title/PageTitleServicesInside';
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';

const ServiceOperativeDermatologie = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');

    const {t} = useTranslation();

    return (
      <Fragment>
        <MetaTags>
          <meta charSet="UTF-8"/>
          <title>{t('meta.title')}</title>
          <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta name="keywords" content={t('meta.keywords')}/>
          <meta name="description" content={t('meta.description')}/>
          <meta name="robots" content="index, follow, noodp"/>
          <meta name="googlebot" content="index, follow"/>
          <meta name="format-detection" content="telephone=no"/>
        </MetaTags>

        <Loading/>

        <Header logoColor="dark"/>

        <main id="main" className="site-main">
          <PageTitleServicesInside title={t('services.service_2.title')}/>


          <div id="page-content" className="spacer p-top-xl">
            <div className="wrapper">
              <div className="content">
                <div id="single">
                  <div id="single-content">
                    <div id="img" className="block">
                      <div className="img-no-wrap-1">
                        <div className="img object-fit">
                          <div className="object-fit-cover">
                            <img
                              src="https://ucarecdn.com/1c1ae218-8657-40dd-907b-0d776ef6652a/-/preview/-/quality/smart/-/format/auto/"
                              alt="eye lamination"/>
                          </div>
                        </div>
                      </div>
                    </div>

                      <div id="description" className="block spacer p-top-xl">
                          <p
                              className="description"
                              dangerouslySetInnerHTML={{
                                  __html: t('services.service_2.description').replace(/\n/g, '<br/>'),
                              }}
                          ></p>
                      </div>
                      <BackToServices/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
          <Footer/>
      </Fragment>
    );
    }
;

export default ServiceOperativeDermatologie;
